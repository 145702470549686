.App {
  font-family: DM Sans;
}

html {
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 4px;
  height: 3.5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 20px #E6E6E8;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 0.9px;
  -webkit-box-shadow: inset 0 0 20px #C3C2C7;
  height: 8px;

}


.black {
  color: #000000
}

.blue {
  color: #1280BE;
}

.primaryblue-50 {
  color: #e8ecf3;
}

.primaryblue-100 {
  color: #b9c5da;
}

.primaryblue-200 {
  color: #97a9c8;
}

.primaryblue-300 {
  color: #6782af;
}

.primaryblue-400 {
  color: #496a9f;
}

.primaryblue-500 {
  color: #1c4587;
}

.primaryblue-600 {
  color: #193f7b;
}

.primaryblue-700 {
  color: #143160;
}

.primaryblue-800 {
  color: #0f264a;
}

.primaryblue-900 {
  color: #0c1d39;
}

.secondaryblue-50 {
  color: #f3f4f8;
}

.secondaryblue-100 {
  color: #d9dcea;
}

.secondaryblue-200 {
  color: #c6cbe0;
}

.secondaryblue-300 {
  color: #adb4d1;
}

.secondaryblue-400 {
  color: #9da5c9;
}

.secondaryblue-500 {
  color: #848fbb;
}

.secondaryblue-600 {
  color: #7882aa;
}

.secondaryblue-700 {
  color: #5e6685;
}

.secondaryblue-800 {
  color: #494f67;
}

.secondaryblue-900 {
  color: #373c4f;
}

.offwhite-50 {
  color: #fefeff
}

.offwhite-100 {
  color: #fdfdff
}

.offwhite-200 {
  color: #fbfcff
}

.offwhite-300 {
  color: #fafbff
}

.offwhite-400 {
  color: #f9faff
}

.offwhite-500 {
  color: #f7f9ff
}

.offwhite-600 {
  color: #e1e3e8
}

.offwhite-700 {
  color: #afb1b5
}

.offwhite-800 {
  color: #88898c
}

.offwhite-900 {
  color: #68696b
}

.alabaster-50 {
  color: #fefefd
}

.alabaster-100 {
  color: #fbfbf7
}

.alabaster-200 {
  color: #f9f9f4
}

.alabaster-300 {
  color: #f6f6ee
}

.alabaster-400 {
  color: #f4f4eb
}

.alabaster-500 {
  color: #f1f1e6
}

.alabaster-600 {
  color: #dbdbd1
}

.alabaster-700 {
  color: #ababa3
}

.alabaster-800 {
  color: #85857f
}

.alabaster-900 {
  color: #656561
}

.neutral-1 {
  color: #e6e6e8
}

.neutral-2 {
  color: #c3c2c7
}

.neutral-3 {
  color: #93919a
}

.neutral-4 {
  color: #605e6b
}

.neutral-5 {
  color: #302e3e
}

.neutral-6 {
  color: #030014
}

.neutral-7 {
  color: #030011
}

.neutral-8 {
  color: #02000e
}

.neutral-9 {
  color: #02000b
}

.neutral-10 {
  color: #010009
}

.iris-100 {
  color: #5D5FEF
}

.red {
  color: #EF4737;
}
.blue{
  color:#1280BE;
}

.green {
  color: #4CAF50;
}

.desktop-header-1 {
  font-size: 65px;
  font-weight: bold;
}

.desktop-header-2 {
  font-size: 55px;
  font-weight: medium;
}

.desktop-header-3 {
  font-size: 45px;
  font-weight: bold;
}

.desktop-header-4 {
  font-size: 35px;
  font-weight: medium;
}

.desktop-header-5 {
  font-size: 26px;
}

.desktop-header-6 {
  font-size: 16px;
  font-weight: medium;
}

.desktop-paragraph {
  font-size: 16px;
}

.bold {
  font-weight: bold;
}

.normalFontWeight {
  font-weight: normal;
}

.text-align-center {
  text-align: center;
}

.underline-text {
  text-decoration: underline;
}
.font-11{
  font-size: 11px;
}
.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.labels {
  font-size: 14px;
}

.labelsLinks {
  font-size: 14px;
  cursor: pointer;
}
.completeTask{
  font-size: clamp(12px,1.3vw,14px);
}
.heroTitle{
  font-size: clamp(20px,2.5vw,35px);
  font-weight: medium;
}
.loginDescription{
  font-size: clamp(14px,1vw,16px);
}

.labels-extra-bold {
  font-weight: 700;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-700 {
  font-weight: 700;
}


.mobile-header-1 {
  font-size: 45px;
  font-weight: bold;
}

.mobile-header-2 {
  font-size: 35px;
  font-weight: bold;
}

.mobile-header-3 {
  font-size: 25px;
  font-weight: bold;
}

.mobile-header-4 {
  font-size: 18px;
  font-weight: medium;
}

.mobile-header-5 {
  font-size: 16px;
}

.mobile-paragraph {
  font-size: 16px;
}

.pointerText {
  cursor: pointer;
}

.pointerUnset {
  cursor: unset;
}

.greyLine {
  background-color: #C3C2C7;
  width: 170px;
  height: 1px;
  transform: scaleY(0.5); 
}

.grey-line-full-width {
  background-color: #C3C2C7;
  width: 100%;
  height: 1px
}

.Field-input {
  font-family: DM Sans;
  width: 100%;
}

.blueButton {
  background-color: #5D5FEF;
  width: 100%;
  border-radius: 8px;
  font-weight: bold;
  color: #FEFEFF;
  border: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
  height: 40px;
  padding: 12px;
}

.blueButton .MuiTypography-root {
  margin: 0;
  padding: 0;
  display: inline;
  font-size: inherit;
  line-height: inherit;
}

.blueButton:enabled {
  cursor: pointer;
}

.blueButton:disabled {
  opacity: 0.5;
  cursor: unset;
}
.blueButton:hover:enabled{
  background-color: #7879F1;
}
.blueButton:active:enabled{
  background-color: #B9C5DA;
  border:1px solid #5D5FEF;
}
.deleteButton {
  background-color: #F4D0D0;
  width: 100%;
  border-radius: 10px;
  font-weight: bold;
  color: #EF4737;
  padding: 12px;
  border: 0;
  text-align: center;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
}

.deleteButton:enabled {
  cursor: pointer;
}

.deleteButton:disabled {
  opacity: 0.5;
}

.successButton {
  background-color: #00B69B;
  width: 100%;
  border-radius: 10px;
  font-weight: bold;
  color: #FEFEFF;
  padding: 12px;
  border: 0;
  text-align: center;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
}

.greenButton {
  background-color: #4BB543;
  width: 100%;
  border-radius: 10px;
  font-weight: bold;
  color: #FEFEFF;
  padding: 12px;
  border: 0;
  text-align: center;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.greenButton .MuiTypography-root {
  margin: 0;
  padding: 0;
  display: inline;
  font-size: inherit;
  line-height: inherit;
}

.redButton {
  background-color: #EF4737;
  width: 100%;
  border-radius: 10px;
  font-weight: bold;
  color: #FEFEFF;
  padding: 12px;
  border: 0;
  text-align: center;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
}

.redButton .MuiTypography-root {
  margin: 0;
  padding: 0;
  display: inline;
  font-size: inherit;
  line-height: inherit;
}
.redButton:hover:enabled{
  background-color:#F4D0D0;
}
.redButton:active:enabled{
border: 1px solid #EF4737;
background-color: #F4D0D0;
color:white;
}

.greyButton {
  background-color: #FEFEFF;
  border: 1px solid #93919A;
  text-align: center;
  color: #93919A;
  font-weight: 700;
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
  height: 40px;
  font-family: 14px;
}

.greyButton .MuiTypography-root {
  margin: 0;
  padding: 0;
  display: inline;
  font-size: inherit;
  line-height: inherit;
}

.greyButton:enabled {
  cursor: pointer;
}

.greyButton:disabled {
  opacity: 0.5;
}
.greyButton:hover:enabled{
  background-color:#E6E6E8;
}
.greyButton:active:enabled{
border: 1px solid #5D5FEF;
background-color: #E8ECF3;
color:#5D5FEF;
}

.greenAccessButton {
  background-color: #CCF0EB;
  border: 1px solid #00B69B;
  width: 100%;
  border-radius: 10px;
  font-weight: bold;
  color: #00B69B;
  padding: 12px;
  border: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
  height: 40px;
  cursor: pointer;
}

.greenAccessButton:disabled {
  cursor: unset;
  opacity: 0.5;
}

.passChangeButton {
  background-color: #CCF0EB;
  border: 1px solid #CCF0EB;
  width: 100%;
  border-radius: 10px;
  font-weight: bold;
  color: #00B69B;
  padding: 12px;
  border: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
  height: 40px;
  cursor: pointer;
}

.passChangeButton:disabled {
  cursor: unset;
  opacity: 0.5;
}

.logoutButton {
  background-color: #EF4737;
  text-align: center;
  color: white;
  cursor: pointer;
  font-weight: 700;
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
}

.logoutButton .MuiTypography-root {
  margin: 0;
  padding: 0;
  display: inline;
  font-size: inherit;
  line-height: inherit;
}
.logoutButton:hover:enabled{
  background-color:#F4D0D0;
}
.logoutButton:active:enabled{
border: 1px solid #EF4737;
background-color: #F4D0D0;
color:white;
}

.pointerText {
  cursor: pointer;
}

.errorText {
  color: #EF4737;
  font-weight: medium;
  text-align: center;
}

.character {
  border: none;
  font-size: 35px;
  border-radius: 8px;
  border: solid 1px #93919A;
  /* Default border color */
  height: 72px;
  width: 52px;
  color: #030014;
  display: flex;
  align-items: center;
  justify-content: center;
}

.character.valid {
  border-color: green;
}

.character.invalid {
  border-color: red;
}

.container {
  display: flex;
  gap: 8px;
  width: 365px;
}

.tabs {
  border: 1px solid #E6E6E8;
  font-size: 14px;
  font-weight: bold;
  color: #C3C2C7;
  border-radius: 8px 8px 0px 0px;
  padding: 20px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
}

.activeTabs {
  border-top: 1px solid #E6E6E8;
  border-right: 1px solid #E6E6E8;
  border-left: 1px solid #E6E6E8;
  font-size: 14px;
  font-weight: bold;
  color: #FEFEFF;
  border-radius: 8px 8px 0px 0px;
  padding: 20px;
  cursor: pointer;
  background-color: #5D5FEF;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
}

.active-status {
  border-radius: 4px;
  background-color: #CCF0EB;
  color: #00B69B;
  padding: 10px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-active-status {
  border-radius: 4px;
  background-color: #E6E6E8;
  color: #93919A;
  padding: 10px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invited-status {
  border-radius: 4px;
  background-color: #FBE6B1;
  color: #9F7E3A;
  padding: 10px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bordered-box {
  border: 1px solid #E6E6E8;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 1px;
  cursor: pointer;
}

.green-bordered-box {
  border: 1px solid #00B69B;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 1px;
  cursor: pointer;
}


.table-header {
  color: #130F26;
  font-weight: bold;
  font-size: 14px;
}

.dropdown-container {
  box-shadow: 0px 2px 4px 0px #0000001F;
  background-color: #FEFEFF;
  padding: 7px;
}

.whiteContainer {
  background-color: #FAFBFF;
  border: 1px solid #C3C2C7;
  border-radius: 16px;
  padding: 18px;
}
.whiteContainerCollapsed {
  background-color: #FAFBFF;
  border: 1px solid #C3C2C7;
  border-radius: 16px 0 0 16px;
  padding: 10px;
}

.selected-item {
  color: #5D5FEF;
  background-color: #E5F0FC;
  font-size: 14px;
  border-radius: 8px;
  width: 100%;
  font-weight: 400;
  padding: 8px;
}

.not-selected-item {
  font-size: 14px;
  width: 100%;
  color: #000000;
  font-weight: 400;
  padding: 8px;
}

.dialogContainer {
  box-shadow: 0px 2px 24px 0px #00000029;
  background: #FFFFFF;
  border-radius: 16px;
  border: 0;
}

.css-7o8aqz-MuiSlider-rail {
  color: #FEFEFF;
}

.textfield {
  border-radius: 12px;
  height: 50px;
  border: solid 1px #C3C2C7;
  overflow: auto;
  padding: 7px;
  background-color: #F7F9FF;
  display: grid;
  align-items: center;
  color: #93919A;
}
.textField {
  border-radius: 12px;
  min-height: 50px;
  border: solid 1px #C3C2C7;
  overflow: auto;
  padding: 7px;
  background-color: #F7F9FF;
  display: grid;
  align-items: center;
  color: #93919A;
}

.blueLine {
  background-color: #5D5FEF;
  height: 1px;
  width: 170px;
}

.activeCircle {
  background-color: #5D5FEF;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
}

.InactiveCircle {
  background-color: #E6E6E8;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  border: solid 1px #E6E6E8;
  font-weight: bold;
}

.nbCircle {
  background-color: #C8EBFF;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1280BE;
  font-weight: bold;
}


.css-av0ptn-MuiTypography-root {
  margin: 0;
  font-family: DM Sans;
  font-weight: 400;
  font-size: 1rem;
  line-height: 0;
}


.fieldDesign {
  border-radius: 8px !important;
  font-family: DM Sans;
  height: 40px;
  background-color: #F7F9FF;
}

.descriptionDesign {
  border-radius: 8px !important;
  font-family: DM Sans;
  /* height: 80px; */
  background-color: #F7F9FF;
}

.statusFieldDesign {
  border-radius: 8px !important;
  font-family: DM Sans;
  height: 40px;
  background-color: #CCF0EB;
}

.OpenStatusFieldDesign {
  border-radius: 8px !important;
  font-family: DM Sans;
  height: 40px;
  background-color: #FBE6B1;
}

.InProgressStatusFieldDesign {
  border-radius: 8px !important;
  font-family: DM Sans;
  height: 40px;
  background-color: #CCF0EB;
}

.OverdueStatusFieldDesign {
  border-radius: 8px !important;
  font-family: DM Sans;
  height: 40px;
  background-color: #EF4737;
}

.ClosedStatusFieldDesign {
  border-radius: 8px !important;
  font-family: DM Sans;
  height: 40px;
  background-color: #1F845A;
}

.WaitingForOthersStatusFieldDesign {
  border-radius: 8px !important;
  font-family: DM Sans;
  height: 40px;
  background-color: #EAE9FC;
}

.UpcomingStatusFieldDesign {
  border-radius: 8px !important;
  font-family: DM Sans;
  height: 40px;
  background-color: #FFD1F8;
}

.fieldDesignNoBorder {
  font-family: DM Sans;
  height: 40px;
  border: none !important;
}

.mention-component {
  position: relative;
}

textarea {
  width: 100%;
  box-sizing: border-box;
}

.suggestions {
  border: 1px solid #ccc;
  background: #fff;
  position: absolute;
  max-height: 150px;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
}

.suggestion-item {
  padding: 5px;
  cursor: pointer;
}

.suggestion-item:hover:active {
  background: #eee;
}

.user-profile-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: #5D5FEF;
  color: #ffffff;
  font-size: 25px;
  border-radius: 50%;
  font-weight: 800;
}

.test {
  position: relative;
  width: 264px;
  height: 184px;
  top: 20px;
  left: 20px;

  padding: 10px 0 0 0;
  gap: 12px;

  /* Base transparent border */
  border: 2px solid transparent;

  /* Gradient border */
  border-image-source: linear-gradient(180deg, #FBE6B1 0%, #F9F1DA 100%);
  border-image-slice: 1;
  /* Ensures the gradient spans the full border */

  /* Apply rounded corners */
  border-radius: 16px;

  opacity: 1;
  overflow: hidden;
  /* Prevent overflow */
}

.box {
  position: relative;
  padding: 20px 30px;
  margin: 5px;
  display: inline-block;
  font-size: 30px;
  border-radius: 16px; /* Apply rounded corners */
  background: white; /* Box background */
  border: 6px solid transparent; /* Transparent base border */
  border-image: linear-gradient(45deg, #C8EBFF 0%, #69BAE8 100%) 1; /* Gradient border */
}

/* Create the gradient border using ::before */
.box::before {
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  border-radius: 16px; /* Rounded corners for the border */
  background: linear-gradient(45deg, #C8EBFF 0%, #69BAE8 100%); /* Gradient effect */
  z-index: -1; /* Ensure the border stays behind the content */
}

.lineHeight18 {
  line-height: 18px;
}

.greyCircle {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border: solid 1px#E6E6E8;

}

/* radio button */
.lightGreyCircle {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border: solid 1px #c3c2c7;

}

.darkGreyCircle {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border: solid 1px #93919a;
}

.blueCircle {
  width: 12.5px;
  height: 12px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border: solid 1px #5D5FEF;
  background-color: #5D5FEF ;
}

.blackCircle {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border: solid 1px #000;
}

.daySelected {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: #5D5FEF;
  color: white;

}
.custom-date-range .rdrMonthAndYearWrapper{
  max-width: 290px !important;
  border-radius: 10px;
}

.custom-date-range .rdrMonth
 {
  max-width: 270px !important; 
}
.custom-date-range .rdrCalendarWrapper .rdrDateRangeWrapper{
  max-width: 300px !important;
}

.custom-link,.custom-link:active,.custom-link:visited,.custom-link:hover {
  color: blue;
  text-decoration: underline;
}
.MuiTableCell-head {
  vertical-align: middle !important;
}
.selected-module{
  padding: inherit; 
}
.selected-module:hover{
background-color: rgba(0, 0, 0, 0.04);
}
.selected-module.selected {
  background-color: #E6E6E8;
  color: #5D5FEF;
}
.selected-type{
  padding: 4px 0 4px 13px; 
}
.openedTaskType{
  padding: 4px 0 4px 13px;
}
.selected-type:hover{
background-color: rgba(0, 0, 0, 0.04);
}
.selected-type.selected {
  background-color: #E6E6E8;
  color: #5D5FEF;
}
.selected-type-info{
  padding: 4px 0 4px 13px; 
}
.selected-type-info:hover{
background-color: rgba(0, 0, 0, 0.04);
}
.selected-type-info.selected {
  background-color: #E6E6E8;
  color: #5D5FEF;
}
.scroll-container::-webkit-scrollbar {
  margin-top: 100px;
}
.scroll-container::-webkit-scrollbar-track {
  margin-top: 100px; 
}
.scroll-container::-webkit-scrollbar-thumb {
  margin-top: 100px; 
}